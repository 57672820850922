//UAT Server
//const host = 'http://139.59.78.109:8005/'
//live server
//const host = 'http://10.139.240.197:8005/'
//const host = 'http://localhost:8000/'
  //const host = 'http://165.232.191.130:8000/'
 const host = 'https://kycbackend02.do-blr.mpgpsdc.com/'
  

export default host
