 import React, { Component, Suspense } from 'react';
 import $ from 'jquery';
//import { Redirect } from 'react-router-dom';
 import './App.css';
 import './kyc.scss';
 import MainRoute from './MainRoute';
//import Login from './Login';
//import CircularProgress from '@material-ui/core/CircularProgress';

 //import packageJson from '../../../app/my-app/package.json';
 import packageJson from '../../mpkyc/package.json';

 import ApiUrlCall from './Component/API/apiurl';
//import './i18n'
//import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
// import Tooltip from '@material-ui/core/Tooltip';
// import Switch from '@material-ui/core/Switch';
  import { Grid } from '@material-ui/core';
// import StarRatings from 'react-star-ratings';
// import constant from '../src/Component/constant.json'
//import Footer from './Component/footer.js';
// import CommonFunction from '../src/Component/common_function.js'
import Snackbar from '@material-ui/core/Snackbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Stringify from 'react-stringify';
import { useMediaQuery } from 'react-responsive';

 

class App extends Component {
  constructor(props) {
    super(props);
  
  

  }

  componentWillMount() {

  }

  componentDidMount() {
    
    this.getIpAddress()

   
  }

 

  getIpAddress = () => {
    ApiUrlCall.getIpAddress().then(response => {
       //console.log('ip address response', response)
    }).catch(error => {
      //console.log('error getting ip is', error)
    })
  }
  

  

  render() {
   
    const { t } = this.props
    

    return (
      <div className="App">
      <Suspense fallback={null}>
        <MainRoute />
      </Suspense>
      
      </div>
      
     
    );
  }
}

export default App;

