import axios from 'axios';
import host from '../ipaddress'
import { Component } from 'react';
import { type } from 'jquery';


const headers =
{
    'Content-Type': 'application/json',
    'user': "3",
    'timezone': 'Asia/Kolkata',
    'token':  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozfQ.talM-Qnb8ybMnYlBZ9IxJmOvZMKoMqiVoxqvKrbl_qw"
}


class ApiUrlCall {

    getIpAddress(){
        return axios.get('https://api.ipify.org?format=json')
    }

    getKycDetails(data) {
        return axios.post(host + 'track/get_kyc_details/ ', data, { headers: headers })
    }
    
    updateKycDetails(data) {
        //console.log(data)
        return axios.post(host + 'track/update_kyc_details/ ', data, { headers: headers })
    }
    
}

export default new ApiUrlCall();
