import React, { useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom'
//import { useSelector } from "react-redux";
//import jwtDecode from 'jwt-decode';


const authentication = {
  isAuthenticated : false,
  // token : localStorage.getItem('token'),
  loggedin(){    
    let token = localStorage.getItem('token')
    // console.log(token , "GOTTE TOKEN IN LOGGED ")
    if(token){
      // console.log('token')
      this.isAuthenticated = true;
    }else{
      // console.log('no token')
      this.isAuthenticated = false;
    }
    // setTimeout(cb,100)
  }
}



const PrivateRoute = ({ component: Component, ...rest }) => {  
  // console.log('in private route', )
  authentication.loggedin()

    return (
      <Route {...rest} render={props =>
        !authentication.isAuthenticated ? (
          <Redirect to={{
            pathname : '/login',
            state : {from : props.location}
          }} />
        ) : (
          <Component {...props} />
        )
      }
      />
    );
  ;
}
  

export default PrivateRoute;