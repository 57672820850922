import React, { Component, Suspense } from 'react';
import $ from 'jquery';
//import { Redirect } from 'react-router-dom';
import './App.css';
import './kyc.scss';
//import MainRoute from './Component/dashboard/MainRoute';
//import Login from './Login';
//import CircularProgress from '@material-ui/core/CircularProgress';

//import packageJson from '../../../app/my-app/package.json';
import packageJson from '../../mpkyc/package.json';

import ApiUrlCall from './Component/API/apiurl';
//import './i18n'
//import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
// import Tooltip from '@material-ui/core/Tooltip';
// import Switch from '@material-ui/core/Switch';
 import { Grid } from '@material-ui/core';
// import StarRatings from 'react-star-ratings';
// import constant from '../src/Component/constant.json'
//import Footer from './Component/footer.js';
// import CommonFunction from '../src/Component/common_function.js'
import Snackbar from '@material-ui/core/Snackbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import Stringify from 'react-stringify';
import { useMediaQuery } from 'react-responsive';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { blueGrey } from '@material-ui/core/colors';



  

const data1 = [
    {
        label: '',
        field: 'checkbox',
        sort: 'asc',
        width: 50,
    },
    {
        label: 'VEHICLE NAME',
        field: 'vehicle_name',
        sort: 'asc',
        width: 120,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'REGISTRATION NO',
        field: 'reg_number',
        sort: 'asc',
        width: 130,
    },
    
]

class Kyc extends Component {
 constructor(props) {
   super(props);
 
   this.state = {
     height_state:'',
     get_rmn1: '',
     get_rmn2: '',
     get_rmn3: '',
     get_email:'',
     get_vehicle_list : [],
     get_len_list :'',
     kyc_vehicles:[],
     send_rmn1:'',
     send_rmn2:'',
     send_rmn3:'',
     send_email:'',
     datatable: [],
     message : "",
     errorMessage : "",



   }


 }

 componentWillMount() {

 }

 componentDidMount() {


    //console.log("user_id" ,localStorage.getItem("user_id"))
   var sendJsonUniqueId = {
     user_id:localStorage.getItem("user_id"),    
   }
   
   var sendJsonUniqueId = JSON.stringify(sendJsonUniqueId)

   var loginHeight = $(window).outerHeight()
   //console.log('height of the login',loginHeight)
   this.height(loginHeight)
   this.getKycDetails(sendJsonUniqueId)

  
 }

 height = (loginHeight) => {
   this.setState({
     height_state: loginHeight,
  
   })
 }
 

 rmn1Validation = (e) => { 
   
     //console.log('111111',e.currentTarget.value.length)   
     if(e.currentTarget.value.length !== 10){
  
       $('#rmn1_status').slideDown()
       setTimeout(() => {
         const input = document.querySelector("#rmn1");
         input.focus();
         $('#rmn1_status').fadeOut("slow")
       },1000);  
 
     }
   
 }


 validateEmail = (user_email) => {
   //console.log(user_email,"sssssssssss")
   var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
   var user_email = document.getElementById('user_email').value
   if(user_email.length != 0){
     
   if(user_email.match(reg)){
     //console.log("ddddddddddddd")
     return true;
   }else{
    
     $('#email_status').slideDown()
     setTimeout(() => {
       const input = document.querySelector("#user_email");
       input.focus();
       $('#email_status').fadeOut("slow")
     },1000);
   }
 }
 return true
}

  
 getKycDetails = (sendJsonUniqueId) => {
 
    //console.log(sendJsonUniqueId, "unique id")
   ApiUrlCall.getKycDetails(sendJsonUniqueId).then(response => {
     if (response.data.is_success === true) {
       $("#processing").hide()

       this.setState({
         get_rmn1: response.data.data.rmn_1,
         get_rmn2: response.data.data.rmn_2,
         get_rmn3: response.data.data.rmn_3,
         get_email: response.data.data.email_address,
         get_vehicle_list : response.data.data.vehicle_list,
         get_len_list : response.data.data.vehicle_list.length,
         send_rmn1: response.data.data.rmn_1,
         send_rmn2: response.data.data.rmn_2,
         send_rmn3: response.data.data.rmn_3,
         send_email: response.data.data.email_address,


       }, () => {
         console.log(response,"res")
         this.createTableData()
       }
       )
     }
   }).catch(error => {
     console.log(error, "error while fetching vehicle list");
   })
 }


 vehicleNameSet = (e,i) => {
   const vehicle_name_id = "vs"+i
   const vehicle_name = e.currentTarget.value
  this.state.get_vehicle_list[i].vehicle_name = vehicle_name

   this.forceUpdate()

 }


 RegNameSet = (e,i) => {
  const reg_id = "rs"+i
  const reg_no = e.currentTarget.value
  this.state.get_vehicle_list[i].registration_number = reg_no
  this.forceUpdate()

}
 
 iskycUsing = (i,e) => {

   if(e.currentTarget.checked){
     this.state.get_vehicle_list[i].is_using_vehicle = "True"
   }
   else{
     this.state.get_vehicle_list[i].is_using_vehicle = "False"

   }
   this.forceUpdate()

 }

 updateUserDetails =() =>{
   var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
   var user_email2 = document.getElementById('user_email').value
   var noProceed1 =  document.querySelector("#rmn1").value;

   
   //console.log(document.querySelector("#user_email"),"my value")




     if ( (user_email2.length != 0 ||user_email2.match(reg)) && ((noProceed1.length == 10  )  )){
    //console.log(this.state.get_vehicle_list,"list")

   var sendJsonData = {
     vehicle_list: this.state.get_vehicle_list,
     rmn_1 : this.state.send_rmn1,
     rmn_2 : this.state.send_rmn2,
     rmn_3 : this.state.send_rmn3,
     email_address : this.state.send_email,
     user_id:localStorage.getItem("user_id"), 
   }
   
   var jsonSendJsonData = JSON.stringify(sendJsonData)
   console.log('kyc edit data is', jsonSendJsonData) 
   ApiUrlCall.updateKycDetails(jsonSendJsonData).then(response => {
     // console.log("success", response)
     if (response.status === 200) {
      this.setState({
        message : response.data.response_message
      })

       $('#update_status').slideDown()
     }
     setTimeout(() => {
            window.location.href = "https://matchpointgps.com/";
            
 
         }, 2000);
         
         localStorage.clear()
  
   }).catch(error => {
     this.setState({
       openAlert : true,
       errorMessage : "Kindly Enter Proper Details"
     })
     console.log("error while updating data", error.response.data)
   })
 }else{
 $('#total_status').slideDown()
  setTimeout(() => {
   $('#total_status').fadeOut("slow")
 },1000);

}
}
   
 


 createTableData = () => {
    const { get_vehicle_list, datatable } = this.state
    for (let i = 0; i < get_vehicle_list.length; i++) {
        const element = get_vehicle_list[i];

        var row_data = {
           
            vehicle_name: <input type="text" className="form-control" id={"vs" +i} placeholder={('Vehicle Name')} defaultValue={this.state.get_vehicle_list[i].vehicle_name} 
            onChange={(e) => this.vehicleNameSet(e,i)} ></input>,
            reg_number: <input type="text" className="form-control" id={"rs" +i} placeholder={('Registration Number')} defaultValue={this.state.get_vehicle_list[i].registration_number} 
            onChange={(e) => this.RegNameSet(e,i)} ></input>,
            checkbox: <input type="checkbox" 
                    onChange={(e) => this.iskycUsing(i,e)}
                    ></input>
            
           
        }
        datatable.push(row_data)
    }

}

   

  
 render() {
  

   return (

    
       
       <div className="kyc_parent_detail" style={{height:1900,backgroundColor:' #222222'}}>
       <div className="kyc_details_div" style={{height:1900}}>
    
       <div class="header" id="myHeader">
       <h2><img src="images/logo.png" alt="matchpointgps log" width="200" height="20px" /></h2>
         </div>
       {/* <div className="kyc_details_div"> */}
       <p className="kyc_header">KYC Details</p>
         <br></br>
         <label id ="rmn1_label" className="switch_to_latest">PRIMARY MOBILE NUMBER</label>
         <Grid container>
         <Grid item xs={12}>
         <input type="number" className="form-control" id="rmn1"  placeholder={('RMN1')} defaultValue={this.state.get_rmn1} onBlur={this.rmn1Validation}
                   onChange={(e) => {
                     this.state.send_rmn1 = e.currentTarget.value
                   }} ></input>
                     </Grid>
       </Grid>      

         <br></br>
         <label id ="rmn1_label" className="switch_to_latest">EMAIL ADDRESS</label>
         <br></br>
         <Grid container>
         <Grid item xs={12}>
         <input type="text" className="form-control" id="user_email" placeholder={('Email')} defaultValue={this.state.get_email} onBlur={this.validateEmail}
                   onChange={(e) => {
                     this.state.send_email = e.currentTarget.value
                   }}></input>
             </Grid>
         </Grid>            
          <br></br>
               {/* <Grid container spacing={2}>
               {this.returNumberOfVehicles(this.state.get_len_list)}
             </Grid> */}
          <br></br> 
             {/* <Grid item xs={12}>
               <div className="btn_div" >
           <button className="btn sj_btn" onClick={this.updateUserDetails}>{('Submit')}</button>
         </div> 
         </Grid>           */}
       {/* </div> */}

       <div style={{ marginBottom: "10px" }}>
                    <MDBDataTable className = "mbd_datatable"
                        displayEntries={false}
                        searching={false}
                        //theadColor={"blueGrey"}
                        tbodyColor={"black"}
                        maxHeight="400px"
                        paging={true}
                       noBottomColumns = {true}
                        //scrollX
                        entries={10}
                        striped
                        bordered
                        paginationLabel={['Prev', 'Next']}
                        entriesOptions={['5','10','15']}
                        data={{ columns: data1, rows: this.state.datatable }}
                    />

                </div>
                <Grid>
           <label style = {{color :"white"}}>By clicking ✅ ,Please confirm that you are using the vehicle that is fitted with a matchpointGPS tracker.</label>
         </Grid>
         <br/>

         <Grid item xs={12}>
               <div className="btn_div" >
           <button className="btn sj_btn" onClick={this.updateUserDetails}>{('Submit')}</button>
         </div> 
         </Grid>          
             
    

     </div>
     <div id="update_status" className="modal_parent">
                       <div className="modal_body">
                           <p className="cj_w_header" style={{ textAlign: "center" }}><b>{this.state.message}</b></p>
                           <div style={{ textAlign: "center" }}>
                           </div>
                       </div>
       </div>
       <div id="rmn1_status" className="modal_parent">
                       <div className="modal_body">
                           <p className="rmn" style={{ textAlign: "center" }}><b>Invalid RMN1</b></p>
                           <div style={{ textAlign: "center" }}>
                           </div>
                       </div>
         </div>
        
         <div id="email_status" className="modal_parent">
                       <div className="modal_body">
                           <p className="rmn" style={{ textAlign: "center" }}><b>Invalid Email Address</b></p>
                           <div style={{ textAlign: "center" }}>
                           </div>
                       </div>
         </div>
         <div id="total_status" className="modal_parent">
                       <div className="modal_body">
                           <p className="rmn" style={{ textAlign: "center" }}><b>Invalid Details</b></p>
                           <div style={{ textAlign: "center" }}>
                           </div>
                       </div>
         </div>
       <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
         autoHideDuration={4000}
         onClose={(e, reason) => {
           if (reason === 'clickaway') {
             return;
           }
           this.setState({ openAlert: false })
         }
         }
         open={this.state.openAlert}
         message={this.state.errorMessage}
       />
     {/* </div> */}
     </div>
     
    
   );
 }
}

export default Kyc;

